import React, { useContext, useEffect, useRef, useState } from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import { CartContext } from "../../App";
import RateToStar from "../../utils/rateToStart";

const ProductCard = ({ data, home }) => {
  const { name, category, price, special_price, img, _id, rate, reviews } =
    data;
  const myRef = useRef(null);
  const [addCart, setAddCart] = useState();
  const [countProduct, setcountProduct] = useState(1);

  const { cart, setCart } = useContext(CartContext);

  useEffect(() => {
    const valid = cart.find((e) => e._id == _id);
    if (valid) {
      setAddCart(true);
      setcountProduct(valid?.count);
    } else {
      setAddCart(false);
    }
  }, [cart]);

  async function handleAdd(params) {
    const productCard = {
      name,
      category,
      price,
      special_price,
      img,
      _id,
      count: countProduct,
    };

    setCart((current) => [...current, productCard]);
    setAddCart(true);
  }

  function handleDelete() {
    const newCart = cart.filter((e) => e._id != _id);
    setCart(newCart);
    setAddCart(false);
  }

  function handleAddCount() {
    setcountProduct(countProduct + 1);
    const newCart = cart.filter((e) => e._id != _id);
    const productCard = {
      name,
      category,
      price,
      special_price,
      img,
      _id,
      count: countProduct + 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  function handleDecreseCount() {
    setcountProduct(countProduct - 1);
    const newCart = cart.filter((e) => e._id != _id);
    const productCard = {
      name,
      category,
      price,
      special_price,
      img,
      _id,
      count: countProduct - 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  return (
    <div
      className={`${
        home ? "col-xl-3" : "col-xl-4"
      } col-lg-4 col-md-6 col-6 u-s-m-b-30 filter__item headphone pr-0 pl-1`}
    >
      <Reveal height>
        <div className="product-o product-o--hover-on product-o--radius flex flex-col justify-between h-full">
          <Link to={"/products/" + _id}>
            <div className="product-o__wrap">
              <a
                className="aspect aspect--bg-grey aspect--square u-d-block"
                href="product-detail.html"
              >
                <img
                  className="aspect__img object-cover"
                  // src="/images/product/electronic/product3.jpg"
                  src={CDN_BASE_URL + img}
                  alt=""
                />
              </a>
              <div className="product-o__action-wrap">
                <ul className="product-o__action-list">
                  <li>
                    <a
                      data-modal="modal"
                      data-modal-id="#quick-look"
                      data-tooltip="tooltip"
                      data-placement="top"
                      title="Quick View"
                    >
                      <i className="fa-solid fa-search-plus"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <span className="product-o__category">
              <a href="shop-side-version-2.html">{category.name}</a>
            </span>

            <span className="product-o__name">
              <a href="product-detail.html">{name}</a>
            </span>
            <div className="product-o__rating gl-rating-style">
              <RateToStar number={rate} />

              <span className="product-o__review">({reviews?.length}) <i className="fa fa-comment"></i></span>
            </div>

            <span className="product-o__price">
              {special_price && (
                <>
                  <span className="product-o__discount">
                    {price.toLocaleString()} تومان{" "}
                  </span>
                  <br />
                </>
              )}
              {special_price
                ? special_price.toLocaleString()
                : price.toLocaleString()}
              تومان
            </span>
          </Link>
          <div class="table-p__input-counter-wrap mt-3">
            {addCart ? (
              <div class="input-counter">
                <span
                  onClick={() => {
                    countProduct > 1
                      ? handleDecreseCount()
                      : countProduct == 1
                      ? handleDelete()
                      : setAddCart(true);
                  }}
                  class="input-counter__minus fa-solid fa-minus"
                ></span>

                <input
                  class="input-counter__text input-counter--text-primary-style"
                  type="text"
                  value={countProduct}
                  data-min={1}
                  data-max="1000"
                  ref={myRef}
                />

                <span
                  onClick={() => handleAddCount()}
                  class="input-counter__plus fa-solid fa-plus"
                ></span>
              </div>
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setAddCart(true);
                  handleAdd();
                }}
                className="btn w-full p-3 rounded bg-[--brand] text-white"
              >
                افزودن به سبد
              </button>
            )}
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default ProductCard;
