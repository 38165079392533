import React, { useState } from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import queryString from "query-string";

const Filter = ({ category, categoryQuery, setselected, show, setshow }) => {
  const [minprice, setMinprice] = useState();
  const [maxinprice, setmaxinprice] = useState();
  const [open, setOpen] = useState(false);

  return (
    <div class="col-lg-3 col-md-12">
      <div class="shop-w-master">
        <div
          class={`${
            !show && "left-[-100%]"
          } fixed left-0 top-0 h-full w-[250px] bg-white z-10 duration-500`}
        >
          <div class="w-full border-b p-1">
            <span onClick={() => setshow(false)} class="ah-close">
              ✕
            </span>
          </div>
          <div class="u-s-m-b-30 bg-white rounded-[15px]">
            <div class="">
              <div class="shop-w__intro-wrap">
                <h1 class="shop-w__h">دسته بندی</h1>
              </div>
              <ul class="px-4 text-xs">
                {category?.map((e) => {
                  if (e.subCategory.length) {
                    return (
                      <>
                        <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                          <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                            <span class="text-brand-dark capitalize py-0.5">
                              {e.name}
                            </span>
                            <span
                              onClick={() => {
                                setOpen(!open);
                              }}
                              class="w-[22px] h-[22px] ltr:ml-auto rtl:mr-auto"
                            >
                              <i className="fa-solid fa-angle-down mt-1 mr-1"></i>
                            </span>
                          </button>
                        </li>
                        {open &&
                          e.subCategory?.map((sub) => {
                            return (
                              <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                                <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                                  <span class="text-brand-dark capitalize py-0.5">
                                    {sub}
                                  </span>
                                  <span class="w-[22px] text-white h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand] bg-[--brand]">
                                    {categoryQuery.subCategory == sub ? (
                                      <Link
                                        to={{
                                          search: queryString.stringify({
                                            ...categoryQuery,
                                            subCategory: "",
                                            category: "",
                                          }),
                                        }}
                                        onClick={() => setselected("")}
                                        className="fa-solid fa-check"
                                      ></Link>
                                    ) : (
                                      <Link
                                        to={{
                                          search: queryString.stringify({
                                            ...categoryQuery,
                                            subCategory: sub,
                                            category: e._id,
                                          }),
                                        }}
                                        onClick={() => setselected(sub)}
                                      >
                                        <i className="fa-solid fa-circle"></i>
                                      </Link>
                                    )}
                                  </span>
                                </button>
                              </li>
                            );
                          })}
                      </>
                    );
                  } else {
                    return (
                      <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                        <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                          <span class="text-brand-dark capitalize py-0.5">
                            {e.name}
                          </span>
                          <span class="w-[22px] text-white h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand] bg-[--brand]">
                            {categoryQuery.category == e._id ? (
                              <Link
                                to={{
                                  search: queryString.stringify({
                                    ...categoryQuery,
                                    category: "",
                                  }),
                                }}
                                onClick={() => setselected("")}
                                className="fa-solid fa-check"
                              ></Link>
                            ) : (
                              <Link
                                to={{
                                  search: queryString.stringify({
                                    ...categoryQuery,
                                    category: e._id,
                                  }),
                                }}
                                onClick={() => setselected(e.name)}
                              >
                                <i className="fa-solid fa-circle"></i>
                              </Link>
                            )}
                          </span>
                        </button>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div class="u-s-m-b-30 bg-white rounded-[15px]">
            <div class="">
              <div class="shop-w__intro-wrap">
                <h1 class="shop-w__h">نوع محصول</h1>
              </div>
              <ul class="px-4 text-xs">
                <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                  <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                    <span class="text-brand-dark capitalize py-0.5">
                      محصولات تخفیف دار
                    </span>
                    <span class="w-[22px] text-white h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand] bg-[--brand]">
                      {categoryQuery.discounted == "true" ? (
                        <Link
                          to={{
                            search: queryString.stringify({
                              ...categoryQuery,
                              discounted: "",
                            }),
                          }}
                          className="fa-solid fa-check"
                        ></Link>
                      ) : (
                        <Link
                          to={{
                            search: queryString.stringify({
                              ...categoryQuery,
                              discounted: "true",
                            }),
                          }}
                        >
                          <i className="fa-solid fa-circle"></i>
                        </Link>
                      )}
                    </span>
                  </button>
                </li>
                <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                  <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                    <span class="text-brand-dark capitalize py-0.5">
                      محصولات موجود
                    </span>
                    <span class="w-[22px] text-white h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand] bg-[--brand]">
                      {categoryQuery.balance == "true" ? (
                        <Link
                          to={{
                            search: queryString.stringify({
                              ...categoryQuery,
                              balance: "",
                            }),
                          }}
                          className="fa-solid fa-check"
                        ></Link>
                      ) : (
                        <Link
                          to={{
                            search: queryString.stringify({
                              ...categoryQuery,
                              balance: "true",
                            }),
                          }}
                        >
                          <i className="fa-solid fa-circle"></i>
                        </Link>
                      )}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="u-s-m-b-30 bg-white rounded-[15px]">
            <div class="">
              <div class="shop-w__intro-wrap">
                <h1 class="shop-w__h">قیمت</h1>
              </div>
              <ul class="px-4 text-xs pb-5">
                <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                  <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3 justify-between">
                    <span class="text-brand-dark capitalize py-0.5">
                      حداقل قیمت
                    </span>
                    <input
                      onChange={(e) => setMinprice(e.target.value)}
                      type="number"
                      className="input-text input-text--primary-style w-[50%]"
                    />
                  </button>
                </li>
                <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                  <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3 justify-between">
                    <span class="text-brand-dark capitalize py-0.5">
                      حداکثر قیمت
                    </span>
                    <input
                      onChange={(e) => setmaxinprice(e.target.value)}
                      type="number"
                      className="input-text input-text--primary-style w-[50%] p-0"
                    />
                  </button>
                </li>
                <div className="w-full mt-5">
                  <Link
                    to={{
                      search: queryString.stringify({
                        ...categoryQuery,
                        min_price: minprice,
                        max_price: maxinprice,
                      }),
                    }}
                    className="btn w-full py-2 px-5 rounded bg-[--brand] text-white "
                  >
                    فیلتر
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <Reveal>
          <h1 class="shop-w-master__heading u-s-m-b-30 hidden lg:block">
            <i class="fa-solid fa-filter u-s-m-l-8"></i>

            <span>فیلتر </span>
          </h1>
        </Reveal>
        <Reveal>
          <div class="shop-w-master__sidebar sidebar--bg-snow rounded-[15px] bg-[#eee] hidden lg:block">
            <div class="u-s-m-b-30 bg-white rounded-[15px]">
              <div class="">
                <div class="shop-w__intro-wrap">
                  <h1 class="shop-w__h">دسته بندی</h1>
                </div>
                <ul class="px-4 text-xs">
                  {category?.map((e) => {
                    if (e.subCategory.length) {
                      return (
                        <>
                          <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                            <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                              <span class="text-brand-dark capitalize py-0.5">
                                {e.name}
                              </span>
                              <span
                                onClick={() => {
                                  setOpen(!open);
                                }}
                                class="w-[22px] h-[22px] ltr:ml-auto rtl:mr-auto"
                              >
                                <i className="fa-solid fa-angle-down mt-1 mr-1"></i>
                              </span>
                            </button>
                          </li>
                          {open &&
                            e.subCategory?.map((sub) => {
                              return (
                                <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                                  <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                                    <span class="text-brand-dark capitalize py-0.5">
                                      {sub}
                                    </span>
                                    <span class="w-[22px] text-white h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand] bg-[--brand]">
                                      {categoryQuery.subCategory == sub ? (
                                        <Link
                                          to={{
                                            search: queryString.stringify({
                                              ...categoryQuery,
                                              subCategory: "",
                                              category: "",
                                            }),
                                          }}
                                          onClick={() => setselected("")}
                                          className="fa-solid fa-check"
                                        ></Link>
                                      ) : (
                                        <Link
                                          to={{
                                            search: queryString.stringify({
                                              ...categoryQuery,
                                              subCategory: sub,
                                              category: e._id,
                                            }),
                                          }}
                                          onClick={() => setselected(sub)}
                                        >
                                          <i className="fa-solid fa-circle"></i>
                                        </Link>
                                      )}
                                    </span>
                                  </button>
                                </li>
                              );
                            })}
                        </>
                      );
                    } else {
                      return (
                        <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                          <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                            <span class="text-brand-dark capitalize py-0.5">
                              {e.name}
                            </span>
                            <span class="w-[22px] text-white h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand] bg-[--brand]">
                              {categoryQuery.category == e._id ? (
                                <Link
                                  to={{
                                    search: queryString.stringify({
                                      ...categoryQuery,
                                      category: "",
                                    }),
                                  }}
                                  onClick={() => setselected("")}
                                  className="fa-solid fa-check"
                                ></Link>
                              ) : (
                                <Link
                                  to={{
                                    search: queryString.stringify({
                                      ...categoryQuery,
                                      category: e._id,
                                    }),
                                  }}
                                  onClick={() => setselected(e.name)}
                                >
                                  <i className="fa-solid fa-circle"></i>
                                </Link>
                              )}
                            </span>
                          </button>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
            <div class="u-s-m-b-30 bg-white rounded-[15px]">
              <div class="">
                <div class="shop-w__intro-wrap">
                  <h1 class="shop-w__h">نوع محصول</h1>
                </div>
                <ul class="px-4 text-xs">
                  <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                    <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                      <span class="text-brand-dark capitalize py-0.5">
                        محصولات تخفیف دار
                      </span>
                      <span class="w-[22px] text-white h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand] bg-[--brand]">
                        {categoryQuery.discounted == "true" ? (
                          <Link
                            to={{
                              search: queryString.stringify({
                                ...categoryQuery,
                                discounted: "",
                              }),
                            }}
                            className="fa-solid fa-check"
                          ></Link>
                        ) : (
                          <Link
                            to={{
                              search: queryString.stringify({
                                ...categoryQuery,
                                discounted: "true",
                              }),
                            }}
                          >
                            <i className="fa-solid fa-circle"></i>
                          </Link>
                        )}
                      </span>
                    </button>
                  </li>
                  <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                    <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3">
                      <span class="text-brand-dark capitalize py-0.5">
                        محصولات موجود
                      </span>
                      <span class="w-[22px] text-white h-[22px] text-13px flex items-center justify-center border-2 border-border-four rounded-full ltr:ml-auto rtl:mr-auto transition duration-500 ease-in-out group-hover:border-[--brand] text-brand-light border-[--brand] bg-[--brand]">
                        {categoryQuery.balance == "true" ? (
                          <Link
                            to={{
                              search: queryString.stringify({
                                ...categoryQuery,
                                balance: "",
                              }),
                            }}
                            className="fa-solid fa-check"
                          ></Link>
                        ) : (
                          <Link
                            to={{
                              search: queryString.stringify({
                                ...categoryQuery,
                                balance: "true",
                              }),
                            }}
                          >
                            <i className="fa-solid fa-circle"></i>
                          </Link>
                        )}
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="u-s-m-b-30 bg-white rounded-[15px]">
              <div class="">
                <div class="shop-w__intro-wrap">
                  <h1 class="shop-w__h">قیمت</h1>
                </div>
                <ul class="px-4 text-xs pb-5">
                  <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                    <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3 justify-between">
                      <span class="text-brand-dark capitalize py-0.5">
                        حداقل قیمت
                      </span>
                      <input
                        onChange={(e) => setMinprice(e.target.value)}
                        type="number"
                        className="input-text input-text--primary-style w-[50%]"
                      />
                    </button>
                  </li>
                  <li class="flex justify-between items-center transition text-sm md:text-15px bg-fill-base px-0 border-t border-border-base first:border-t-0 mx-[3px] bg-transparent">
                    <button class="flex items-center w-full ltr:text-left rtl:text-right cursor-pointer group py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3 justify-between">
                      <span class="text-brand-dark capitalize py-0.5">
                        حداکثر قیمت
                      </span>
                      <input
                        onChange={(e) => setmaxinprice(e.target.value)}
                        type="number"
                        className="input-text input-text--primary-style w-[50%] p-0"
                      />
                    </button>
                  </li>
                  <div className="w-full mt-5">
                    <Link
                      to={{
                        search: queryString.stringify({
                          ...categoryQuery,
                          min_price: minprice,
                          max_price: maxinprice,
                        }),
                      }}
                      className="btn w-full py-2 px-5 rounded bg-[--brand] text-white "
                    >
                      فیلتر
                    </Link>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default Filter;
