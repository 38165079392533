import "swiper/css";
import "./App.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/404";
import Signup from "./pages/Signup";
import ColorSwitcher from "./components/layout/ColorSwitcher";
import Signin from "./pages/Signin";
import Products from "./pages/Products";
import "./sass/main.scss";
import "./sass/utility.css";
import "./sass/vendor.css";
import ProductDetails from "./pages/ProductDetails";
import ServiceDetails from "./pages/ServiceDetails";
import ScrollToTop from "./utils/ScrooltoTop";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import { createContext, useEffect, useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import OrderDetails from "./pages/OrderDetails";
export const CartContext = createContext();

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <ScrollToTop />
          <Home />
        </>
      ),
    },
    {
      path: "/signup",
      element: (
        <>
          <ScrollToTop />
          <Signup />
        </>
      ),
    },
    {
      path: "/signin",
      element: (
        <>
          <ScrollToTop />
          <Signin />
        </>
      ),
    },
    {
      path: "/cart",
      element: (
        <>
          <ScrollToTop />
          <Cart />
        </>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <>
          <ScrollToTop />
          <Dashboard />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <ScrollToTop />
          <About />
        </>
      ),
    },
    {
      path: "/order/:id",
      element: (
        <>
          <ScrollToTop />
          <OrderDetails />
        </>
      ),
    },
    {
      path: "/products",
      element: (
        <>
          <ScrollToTop />
          <Products />
        </>
      ),
    },
    {
      path: "/checkout",
      element: (
        <>
          <ScrollToTop />
          <Checkout />
        </>
      ),
    },
    {
      path: "/products/:id",
      element: (
        <>
          <ScrollToTop />
          <ProductDetails />
        </>
      ),
    },
    {
      path: "*",
      element: (
        <>
          <ScrollToTop />
          <NotFound />
        </>
      ),
    },
  ]);

  const [cookies, setCookie] = useCookies(["cart"]);

  const [cart, setCart] = useState([]);

  useEffect(() => {
    setCookie("cart", cart);
  }, [cart]);

  async function renderData() {
    const loc = cookies.cart;
    if (loc?.length) {
      setCart(loc);
    }
  }

  useEffect(() => {
    renderData();
  }, []);

  return (
    <>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <CartContext.Provider value={{ cart, setCart }}>
          <ColorSwitcher />
          <RouterProvider router={router} />
        </CartContext.Provider>
      </CookiesProvider>
    </>
  );
}

export default App;
